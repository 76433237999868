#portfolio {
  margin-top: 105px;
  margin-bottom: 80px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  list-style: none;
  padding: 0;
  grid-column-gap: 30px;
  grid-template-rows: auto;
  grid-row-gap: 50px;
  -webkit-box-pack: center;
  -moz-box-pack: center;
  -o-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  justify-items: center;
  padding: 0 30px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
@media (max-width: 1500px) {
  #portfolio {
    grid-template-columns: 1fr 1fr 1fr;
  }
}
@media (max-width: 1200px) {
  #portfolio {
    grid-template-columns: 1fr 1fr;
  }
}
@media (max-width: 850px) {
  #portfolio {
    grid-template-columns: 1fr;
  }
}
#portfolio .portfolio-element {
  max-width: 425px;
  background-color: #f3f3f3;
  position: relative;
  border-radius: 5px;
}
#portfolio .portfolio-element .portfolio-img-wrap img {
  max-height: 250px;
  max-width: 100%;
  padding: 0;
  z-index: 0;
  opacity: 1;
  -ms-filter: none;
  filter: none;
  -webkit-transition: 0.3s all;
  -moz-transition: 0.3s all;
  -o-transition: 0.3s all;
  -ms-transition: 0.3s all;
  transition: 0.3s all;
  position: relative;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}
#portfolio .portfolio-element .portfolio-text-wrap {
  padding: 25px 25px 70px 25px;
  z-index: 2;
}
#portfolio .portfolio-element .portfolio-text-wrap h1.portfolio-title {
  font-size: 20px;
  color: #333;
  font-family: 'Noto Sans';
  margin: 0;
  line-height: 1.42;
}
#portfolio .portfolio-element .portfolio-text-wrap h2.portfolio-subtitle {
  font-size: 18px;
  color: #a561b5;
  font-family: 'Nunito Sans';
  font-style: italic;
  margin: 0 0 10px 0;
  line-height: 1.42;
}
#portfolio .portfolio-element .portfolio-text-wrap h2.portfolio-subtitle:after {
  content: "";
  display: block;
  margin-top: 10px;
  width: 35px;
  height: 1px;
  background-color: #a561b5;
}
#portfolio .portfolio-element .portfolio-text-wrap .portfolio-short {
  font-size: 18px;
  color: #333;
}
#portfolio .portfolio-element .portfolio-text-wrap a.readmore {
  color: #a561b5;
  font-size: 18px;
  padding: 12px 18px;
  border-radius: 5px;
  margin-bottom: 25px;
  position: absolute;
  bottom: 0;
  right: 25px;
}
#portfolio .portfolio-element .portfolio-text-wrap a.readmore svg {
  width: 20px;
  height: 20px;
  display: inline-block;
  vertical-align: middle;
  fill: #a561b5;
  margin-left: -20px;
  margin-bottom: 2px;
  -webkit-transition: 0.25s ease;
  -moz-transition: 0.25s ease;
  -o-transition: 0.25s ease;
  -ms-transition: 0.25s ease;
  transition: 0.25s ease;
  opacity: 0;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  filter: alpha(opacity=0);
}
#portfolio .portfolio-element .portfolio-text-wrap a.readmore:hover {
  background-color: rgba(165,97,181,0.16);
  color: #864696;
}
#portfolio .portfolio-element .portfolio-text-wrap a.readmore:hover svg {
  margin-left: 5px;
  opacity: 1;
  -ms-filter: none;
  filter: none;
}
#portfolio .portfolio-element.portfolio-calltoaction {
  position: relative;
  -webkit-box-shadow: 0 0 0 2px rgba(165,97,181,0.16);
  box-shadow: 0 0 0 2px rgba(165,97,181,0.16);
  display: block;
  cursor: pointer;
}
#portfolio .portfolio-element.portfolio-calltoaction .portfolio-img-wrap {
  opacity: 0;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  filter: alpha(opacity=0);
  -webkit-transition: 0.3s ease;
  -moz-transition: 0.3s ease;
  -o-transition: 0.3s ease;
  -ms-transition: 0.3s ease;
  transition: 0.3s ease;
}
#portfolio .portfolio-element.portfolio-calltoaction .portfolio-text-wrap {
  min-height: 300px;
  opacity: 0;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  filter: alpha(opacity=0);
  -webkit-transition: 0.25s ease;
  -moz-transition: 0.25s ease;
  -o-transition: 0.25s ease;
  -ms-transition: 0.25s ease;
  transition: 0.25s ease;
}
#portfolio .portfolio-element.portfolio-calltoaction svg {
  width: 100px;
  fill: #a561b5;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  -webkit-transition: 0.3s ease;
  -moz-transition: 0.3s ease;
  -o-transition: 0.3s ease;
  -ms-transition: 0.3s ease;
  transition: 0.3s ease;
}
#portfolio .portfolio-element.portfolio-calltoaction:hover {
  -webkit-animation: shadow-pulse 0.85s infinite;
  -moz-animation: shadow-pulse 0.85s infinite;
  -o-animation: shadow-pulse 0.85s infinite;
  -ms-animation: shadow-pulse 0.85s infinite;
  animation: shadow-pulse 0.85s infinite;
}
#portfolio .portfolio-element.portfolio-calltoaction:hover .portfolio-img-wrap,
#portfolio .portfolio-element.portfolio-calltoaction:hover .portfolio-text-wrap {
  opacity: 1;
  -ms-filter: none;
  filter: none;
}
#portfolio .portfolio-element.portfolio-calltoaction:hover svg {
  -webkit-transform: translate(-50%, 50%);
  -moz-transform: translate(-50%, 50%);
  -o-transform: translate(-50%, 50%);
  -ms-transform: translate(-50%, 50%);
  transform: translate(-50%, 50%);
}
@-moz-keyframes shadow-pulse {
  0% {
    -webkit-box-shadow: 0 0 0 0px rgba(165,97,181,0.16);
    box-shadow: 0 0 0 0px rgba(165,97,181,0.16);
  }
  100% {
    -webkit-box-shadow: 0 0 0 5px rgba(165,97,181,0.16);
    box-shadow: 0 0 0 5px rgba(165,97,181,0.16);
  }
}
@-webkit-keyframes shadow-pulse {
  0% {
    -webkit-box-shadow: 0 0 0 0px rgba(165,97,181,0.16);
    box-shadow: 0 0 0 0px rgba(165,97,181,0.16);
  }
  100% {
    -webkit-box-shadow: 0 0 0 5px rgba(165,97,181,0.16);
    box-shadow: 0 0 0 5px rgba(165,97,181,0.16);
  }
}
@-o-keyframes shadow-pulse {
  0% {
    -webkit-box-shadow: 0 0 0 0px rgba(165,97,181,0.16);
    box-shadow: 0 0 0 0px rgba(165,97,181,0.16);
  }
  100% {
    -webkit-box-shadow: 0 0 0 5px rgba(165,97,181,0.16);
    box-shadow: 0 0 0 5px rgba(165,97,181,0.16);
  }
}
@keyframes shadow-pulse {
  0% {
    -webkit-box-shadow: 0 0 0 0px rgba(165,97,181,0.16);
    box-shadow: 0 0 0 0px rgba(165,97,181,0.16);
  }
  100% {
    -webkit-box-shadow: 0 0 0 5px rgba(165,97,181,0.16);
    box-shadow: 0 0 0 5px rgba(165,97,181,0.16);
  }
}
