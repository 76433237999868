footer {
  background-color: #a561b5;
  margin-top: 0;
}
footer .social ul {
  list-style: none;
  padding: 0;
  margin-bottom: 0;
}
footer .social ul li {
  display: inline-block;
  margin: 20px 30px 20px 0;
}
footer .social ul li a img {
  height: 30px;
  opacity: 0.7;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=70)";
  filter: alpha(opacity=70);
  -webkit-transition: 0.3s all;
  -moz-transition: 0.3s all;
  -o-transition: 0.3s all;
  -ms-transition: 0.3s all;
  transition: 0.3s all;
}
footer .social ul li a img:hover {
  opacity: 1;
  -ms-filter: none;
  filter: none;
}
footer .copyright {
  background-color: #3c2a41;
  text-align: center;
  padding: 15px 0 15px;
}
footer .copyright .copyright-divider {
  width: 50px;
  height: 2px;
  background-color: rgba(165,97,181,0.27);
  margin: 7px auto 7px;
}
footer .copyright .copyright-divider +p {
  display: none;
}
footer .copyright p {
  font-size: 14px;
  line-height: 1;
  margin: 0;
  padding: 8px;
  margin: 0 auto;
  color: #bababa;
}
footer .copyright p.touk-name {
  font-size: 14px;
  padding: 6px;
}
@media (max-width: 992px) {
  footer .copyright p {
    width: 80%;
  }
}
@media (max-width: 530px) {
  footer .copyright p {
    width: 90%;
  }
}
footer .copyright p .legal-disclaimer {
  color: #bababa;
}
footer .copyright p a {
  color: #e1e1e1;
}
footer .copyright p a:hover {
  text-decoration: underline;
}
