html body {
  font-weight: 400;
  position: relative;
}
html body *::selection {
  background: #a561b5 !important;
  background-color: #a561b5 !important;
  color: #fff !important;
}
a {
  -webkit-transition: 0.25s all;
  -moz-transition: 0.25s all;
  -o-transition: 0.25s all;
  -ms-transition: 0.25s all;
  transition: 0.25s all;
}
.center {
  text-align: center;
}
.anchor {
  -webkit-transform: translateY(-90px);
  -moz-transform: translateY(-90px);
  -o-transform: translateY(-90px);
  -ms-transform: translateY(-90px);
  transform: translateY(-90px);
}
.horizontalListAnchor {
  -webkit-transform: translate(10px, -150px);
  -moz-transform: translate(10px, -150px);
  -o-transform: translate(10px, -150px);
  -ms-transform: translate(10px, -150px);
  transform: translate(10px, -150px);
}
html body a:hover {
  text-decoration: none;
  outline: none;
}
html body a:focus {
  text-decoration: none;
  outline: none;
}
html body :focus {
  outline-color: transparent;
}
html body h2 {
  margin-bottom: 22px;
}
.positioning-wrapper {
  padding: 0 7% 0 7%;
}
@media (max-width: 1350px) {
  .positioning-wrapper {
    padding: 0 7%;
  }
}
@media (max-width: 992px) {
  .positioning-wrapper {
    padding: 0 2%;
  }
}
p.subheading {
  font-size: 30px;
  font-family: "Nunito Sans";
  margin-bottom: 10px;
  color: #3c2a41;
}
section {
  margin-top: 55px;
  margin-bottom: 50px;
}
.section-dark {
  background-color: #f5f3f5;
}
.section-violet {
  background-color: rgba(165,97,181,0.1);
}
.margintop {
  margin-top: 50px;
}
.margintop-small {
  margin-top: 25px;
}
.section-dark a.primary:after {
  border-color: #f6f6f6 rgba(0,0,0,0);
}
@media 0 screen {
  a.primary:after {
    display: none;
  }
}
a.primary {
  font-size: 20px;
  letter-spacing: 1px;
  font-weight: 700;
  text-transform: uppercase;
  background-color: rgba(165,97,181,0.16);
  color: #864696;
  border: none;
  padding: 12px 40px;
  position: relative;
  margin: 15px 0 10px;
  -webkit-transition: 0.5s all;
  -moz-transition: 0.5s all;
  -o-transition: 0.5s all;
  -ms-transition: 0.5s all;
  transition: 0.5s all;
  display: inline-block;
  text-align: center;
  cursor: pointer;
  border-radius: 5px;
}
@media (max-width: 767px) {
  a.primary {
    font-size: 20px;
  }
}
a.primary.basic-button:after,
a.primary.basic-button:before {
  display: none;
}
a.primary svg {
  width: 24px;
  height: 24px;
  display: inline-block;
  vertical-align: sub;
  fill: #a561b5;
  margin-left: -24px;
  -webkit-transition: 0.3s ease;
  -moz-transition: 0.3s ease;
  -o-transition: 0.3s ease;
  -ms-transition: 0.3s ease;
  transition: 0.3s ease;
  opacity: 0;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  filter: alpha(opacity=0);
}
a.primary:hover {
  background-color: rgba(165,97,181,0.27);
  color: #6d3979;
}
a.primary:hover svg {
  margin-left: 10px;
  opacity: 1;
  -ms-filter: none;
  filter: none;
  fill: #6d3979;
}
a.primary:active {
  background-color: rgba(165,97,181,0.27);
  color: #6d3979;
}
a.primary:focus {
  background-color: rgba(165,97,181,0.27);
  color: #6d3979;
}
@media screen and (-webkit-min-device-pixel-ratio: 0) {
  a.primary ::i-block-chrome,
  a.primary:before {
    display: none;
  }
}
iframe[name="google_conversion_frame"] {
  height: 0;
  position: absolute;
  bottom: 0;
  left: 0;
  background-color: transparent;
}
.noselect {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
