#testimonials-section {
  margin: 0;
  padding: 0 0 40px;
}
#testimonials-section .client-logos {
  text-align: center;
  margin-bottom: 50px;
}
#testimonials-section .client-logos .client {
  margin-top: 20px;
}
@media (max-width: 991px) {
  #testimonials-section .client-logos .client {
    margin-top: 50px;
  }
}
#testimonials-section .swiper-pagination {
  bottom: 0;
}
#testimonials-section .swiper-slide {
  text-align: left;
  height: 100%;
  padding: 5px 20px 55px 20px;
  background: transparent;
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  -webkit-box-pack: center;
  -moz-box-pack: center;
  -o-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -moz-box-align: center;
  -o-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  cursor: default;
}
@media (max-width: 991px) {
  #testimonials-section .swiper-slide {
    padding: 60px 20px 20px 20px;
  }
}
#testimonials-section .swiper-slide .quote-wrapper {
  display: table;
  height: 275px;
}
@media (max-width: 991px) {
  #testimonials-section .swiper-slide .quote-wrapper {
    height: auto;
    margin-bottom: 25px;
  }
  #testimonials-section .swiper-slide .quote-wrapper.quote-person-wrapper {
    height: 70px;
    border-top: 1px solid #ccc;
    margin-bottom: 0;
    margin-left: 23px;
  }
}
#testimonials-section .swiper-slide .quote-wrapper .quote-info {
  display: table-cell;
  vertical-align: middle;
  margin-left: -5px;
}
#testimonials-section .swiper-slide .quote-person-wrapper {
  padding: 25px;
}
#testimonials-section .swiper-slide p.quote {
  margin: 0;
  font-style: italic;
  font-size: 20px;
  line-height: 1.65;
  z-index: 5;
  border-right: 2px solid rgba(165,97,181,0.16);
  padding: 0 25px;
}
@media (max-width: 991px) {
  #testimonials-section .swiper-slide p.quote {
    border-right: none;
    padding: 0;
  }
}
#testimonials-section .swiper-slide p.ref-name {
  font-family: "Noto Sans";
  color: #3c2a41;
  font-size: 20px;
  margin-bottom: 0;
}
#testimonials-section .swiper-slide p.ref-company {
  line-height: 1.4;
  font-size: 18px;
}
#testimonials-section .swiper-slide p.ref-company,
#testimonials-section .swiper-slide p.ref-name {
  margin-bottom: 7px;
}
@media (max-width: 991px) {
  #testimonials-section .swiper-slide p.ref-company,
  #testimonials-section .swiper-slide p.ref-name {
    margin-left: -15px;
  }
}
#testimonials-section svg {
  fill: rgba(165,97,181,0.16);
  height: 200px;
  width: 200px;
  position: absolute;
  top: -25px;
  left: 0;
  z-index: 0;
}
