.swiper-container {
  visibility: hidden;
}
.swiper-container-initialized {
  visibility: visible;
}
.swiper-container .swiper-pagination.swiper-pagination-bullets {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-align: center;
  -moz-box-align: center;
  -o-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  -webkit-box-pack: center;
  -moz-box-pack: center;
  -o-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  height: 32px;
}
.swiper-container .swiper-pagination.swiper-pagination-bullets .swiper-pagination-bullet {
  margin: 0 12px;
  width: 16px;
  height: 16px;
  background-color: #a561b5;
  border-radius: 53px;
  -webkit-transition: 0.25s all;
  -moz-transition: 0.25s all;
  -o-transition: 0.25s all;
  -ms-transition: 0.25s all;
  transition: 0.25s all;
}
@media (max-width: 767px) {
  .swiper-container .swiper-pagination.swiper-pagination-bullets .swiper-pagination-bullet {
    margin: 0 9px;
  }
}
.swiper-container .swiper-pagination.swiper-pagination-bullets .swiper-pagination-bullet-active {
  -webkit-transform: scale(1.25);
  -moz-transform: scale(1.25);
  -o-transform: scale(1.25);
  -ms-transform: scale(1.25);
  transform: scale(1.25);
}
.swiper-button {
  width: 64px;
  height: 64px;
  background: transparent;
  margin: 0;
  padding: 0;
  outline: 0;
  opacity: 0.75;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=75)";
  filter: alpha(opacity=75);
}
.swiper-button:hover, .swiper-button:focus {
  opacity: 1;
  -ms-filter: none;
  filter: none;
}
@media (max-width: 768px) {
  .swiper-button {
    width: 40px;
  }
}
.swiper-button-prev {
  left: 0;
  -webkit-background-size: 64px 64px;
  -moz-background-size: 64px 64px;
  background-size: 64px 64px;
  background-image: url(/lib/3CTP3qaS.svg);
}
.swiper-button-next {
  right: 0;
  -webkit-background-size: 64px 64px;
  -moz-background-size: 64px 64px;
  background-size: 64px 64px;
  background-image: url(/lib/1jGpcliY.svg);
}
