/* noto-sans-700 - latin-ext_latin */
@font-face {
  font-family: 'Noto Sans';
  font-style: normal;
  font-weight: 700;
  src: local(''), url(/lib/3KDibOi8.woff2) format('woff2'), url(/lib/28HSoRnR.woff) format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* nunito-sans-300 - latin-ext_latin */
@font-face {
  font-family: 'Nunito Sans';
  font-style: normal;
  font-weight: 300;
  src: local(''), url(/lib/1FaXTyM-.woff2) format('woff2'), url(/lib/1IlrJI0_.woff) format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* nunito-sans-300italic - latin-ext_latin */
@font-face {
  font-family: 'Nunito Sans';
  font-style: italic;
  font-weight: 300;
  src: local(''), url(/lib/3UQf2YSp.woff2) format('woff2'), url(/lib/1Hylvt6M.woff) format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* nunito-sans-regular - latin-ext_latin */
@font-face {
  font-family: 'Nunito Sans';
  font-style: normal;
  font-weight: 400;
  src: local(''), url(/lib/2yb96JVJ.woff2) format('woff2'), url(/lib/36IXLrOc.woff) format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* nunito-sans-italic - latin-ext_latin */
@font-face {
  font-family: 'Nunito Sans';
  font-style: italic;
  font-weight: 400;
  src: local(''), url(/lib/1DRpyrKW.woff2) format('woff2'), url(/lib/IpX7UP1K.woff) format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* nunito-sans-600 - latin-ext_latin */
@font-face {
  font-family: 'Nunito Sans';
  font-style: normal;
  font-weight: 600;
  src: local(''), url(/lib/Aq_OZU8P.woff2) format('woff2'), url(/lib/DdMwUrao.woff) format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* nunito-sans-700 - latin-ext_latin */
@font-face {
  font-family: 'Nunito Sans';
  font-style: normal;
  font-weight: 700;
  src: local(''), url(/lib/2qXN9O55.woff2) format('woff2'), url(/lib/3l68-bU2.woff) format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* nunito-sans-900 - latin-ext_latin */
@font-face {
  font-family: 'Nunito Sans';
  font-style: normal;
  font-weight: 900;
  src: local(''), url(/lib/3MHG32NI.woff2) format('woff2'), url(/lib/1TzSInzy.woff) format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
