.portfolio-entry {
  margin-top: 60px;
}
.portfolio-entry .portfolio-entry-sidebar {
  position: fixed;
  height: 100%;
  background-color: #f3f3f3;
  width: 450px;
}
@media (max-width: 1550px) {
  .portfolio-entry .portfolio-entry-sidebar {
    width: 350px;
  }
}
@media (max-width: 1200px) {
  .portfolio-entry .portfolio-entry-sidebar {
    width: 280px;
  }
}
@media (max-width: 800px) {
  .portfolio-entry .portfolio-entry-sidebar {
    position: initial;
    width: 100%;
    border: none;
    height: auto;
    margin-top: 76px;
  }
}
.portfolio-entry .portfolio-entry-sidebar .sidebar-container {
  margin: 70px 0 0 120px;
}
@media (max-width: 1550px) {
  .portfolio-entry .portfolio-entry-sidebar .sidebar-container {
    margin: 50px 0 0 70px;
  }
}
@media (max-width: 1200px) {
  .portfolio-entry .portfolio-entry-sidebar .sidebar-container {
    margin: 50px 0 0 40px;
  }
}
@media (max-width: 800px) {
  .portfolio-entry .portfolio-entry-sidebar .sidebar-container {
    margin: 0;
    padding: 30px;
    position: relative;
  }
}
.portfolio-entry .portfolio-entry-sidebar .sidebar-container a.back-button {
  text-align: left;
  display: inline-block;
  margin: -10px 0 0 -10px;
  padding: 0 0;
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  -o-transition: 0.3s;
  -ms-transition: 0.3s;
  transition: 0.3s;
  border-radius: 5px;
  height: 64px;
}
@media (max-width: 1200px) {
  .portfolio-entry .portfolio-entry-sidebar .sidebar-container a.back-button {
    margin: -10px 0 0 -20px;
  }
}
@media (max-width: 800px) {
  .portfolio-entry .portfolio-entry-sidebar .sidebar-container a.back-button {
    position: absolute;
    top: 25px;
    left: 25px;
  }
}
.portfolio-entry .portfolio-entry-sidebar .sidebar-container a.back-button:hover {
  background-color: rgba(165,97,181,0.16);
  padding: 0 30px;
}
.portfolio-entry .portfolio-entry-sidebar .sidebar-container a.back-button:hover p {
  opacity: 1;
  -ms-filter: none;
  filter: none;
  -webkit-transform: translateX(0);
  -moz-transform: translateX(0);
  -o-transform: translateX(0);
  -ms-transform: translateX(0);
  transform: translateX(0);
  padding-right: 25px;
  color: #a561b5;
}
.portfolio-entry .portfolio-entry-sidebar .sidebar-container a.back-button p {
  display: inline-block;
  font-size: 20px;
  vertical-align: top;
  margin: 0;
  height: 64px;
  line-height: 64px;
  -webkit-transition: 0.3s ease;
  -moz-transition: 0.3s ease;
  -o-transition: 0.3s ease;
  -ms-transition: 0.3s ease;
  transition: 0.3s ease;
  opacity: 0;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  filter: alpha(opacity=0);
  -webkit-transform: translateX(-20px);
  -moz-transform: translateX(-20px);
  -o-transform: translateX(-20px);
  -ms-transform: translateX(-20px);
  transform: translateX(-20px);
}
@media (max-width: 800px) {
  .portfolio-entry .portfolio-entry-sidebar .sidebar-container a.back-button p {
    display: none;
  }
}
.portfolio-entry .portfolio-entry-sidebar .sidebar-container a.back-button svg {
  height: 64px;
  width: 64px;
  fill: #a561b5;
  display: inline-block;
  border-radius: 50%;
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  -o-transition: 0.3s;
  -ms-transition: 0.3s;
  transition: 0.3s;
}
.portfolio-entry .portfolio-entry-sidebar .sidebar-container ul.project-data {
  list-style: none;
  padding: 0;
}
@media (max-width: 800px) {
  .portfolio-entry .portfolio-entry-sidebar .sidebar-container ul.project-data {
    display: inline-block;
    vertical-align: top;
    width: 100%;
  }
}
.portfolio-entry .portfolio-entry-sidebar .sidebar-container ul.project-data>li {
  font-size: 16px;
}
@media (max-width: 800px) {
  .portfolio-entry .portfolio-entry-sidebar .sidebar-container ul.project-data>li {
    width: 100%;
    text-align: center;
  }
}
.portfolio-entry .portfolio-entry-sidebar .sidebar-container ul.project-data h3 {
  font-size: 24px;
  margin-bottom: 5px;
  margin-top: 15px;
}
@media (max-width: 1200px) {
  .portfolio-entry .portfolio-entry-sidebar .sidebar-container ul.project-data h3 {
    font-size: 20px;
  }
}
@media (max-width: 800px) {
  .portfolio-entry .portfolio-entry-sidebar .sidebar-container ul.project-data h3 {
    margin: 25px 0 5px 0;
  }
}
.portfolio-entry .portfolio-entry-sidebar .sidebar-container ul.project-data p {
  color: #666;
  font-size: 21px;
  margin: 0;
  line-height: 1.5;
}
@media (max-width: 1200px) {
  .portfolio-entry .portfolio-entry-sidebar .sidebar-container ul.project-data p {
    font-size: 18px;
  }
}
@media (max-width: 800px) {
  .portfolio-entry .portfolio-entry-sidebar .sidebar-container ul.project-data p {
    font-size: 21px;
  }
}
.portfolio-entry .portfolio-entry-sidebar .sidebar-container ul.project-data ul {
  list-style: none;
  padding: 0;
}
.portfolio-entry .portfolio-entry-sidebar .sidebar-container ul.project-data .team-size {
  margin-top: 15px;
}
.portfolio-entry .portfolio-entry-sidebar .sidebar-container ul.project-data .team-size svg {
  height: 35px;
  width: 30px;
  fill: #999;
  margin-right: 8px;
}
.portfolio-entry .portfolio-entry-main {
  margin-left: 450px;
  margin-right: 10vw;
}
@media (max-width: 1550px) {
  .portfolio-entry .portfolio-entry-main {
    margin-left: 350px;
    margin-right: 0;
  }
}
@media (max-width: 1200px) {
  .portfolio-entry .portfolio-entry-main {
    margin-left: 280px;
  }
}
@media (max-width: 800px) {
  .portfolio-entry .portfolio-entry-main {
    margin: 0 auto;
  }
}
.portfolio-entry .portfolio-entry-main .project-description {
  padding: 70px 100px;
}
@media (max-width: 1200px) {
  .portfolio-entry .portfolio-entry-main .project-description {
    padding: 50px 60px;
  }
}
@media (max-width: 800px) {
  .portfolio-entry .portfolio-entry-main .project-description {
    padding: 40px;
  }
}
.portfolio-entry .portfolio-entry-main .project-description h1 {
  font-size: 48px;
  margin: 0 0 10px 0;
}
.portfolio-entry .portfolio-entry-main .project-description h2 {
  font-size: 25px;
  color: #888;
  margin: 5px 0 25px;
  display: inline-block;
  font-family: 'Nunito Sans';
  font-style: italic;
  color: #a561b5;
}
.portfolio-entry .portfolio-entry-main .project-description h2:after {
  content: "";
  display: block;
  margin-top: 25px;
  width: 30px;
  height: 2px;
  background-color: #a561b5;
}
.portfolio-entry .portfolio-entry-main .project-description p {
  font-size: 20px;
  line-height: 1.45;
  margin-bottom: 28px;
}
.portfolio-entry .portfolio-entry-main .project-description p.portfolio-link {
  margin-bottom: 7px;
}
.portfolio-entry .portfolio-entry-main .project-description p.quote {
  border-left: 2px solid #bbb;
  color: #888;
  padding: 0 0 0 15px;
  margin-bottom: 7px;
}
.portfolio-entry .portfolio-entry-main .project-description p.quote-author {
  font-family: "Noto Sans";
  font-size: 20px;
  margin-bottom: 2px;
}
.portfolio-entry .portfolio-entry-main .project-description p.quote-author-position {
  font-size: 18px;
}
.portfolio-entry .portfolio-entry-main .project-description .big-quote {
  padding: 35px;
  position: relative;
  margin: 35px 0 28px;
  border-radius: 5px;
  border: 2px solid #d6d6d6;
  z-index: 0;
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
}
.portfolio-entry .portfolio-entry-main .project-description .big-quote >svg {
  fill: #d6d6d6;
  background-color: #fff;
  position: absolute;
  height: 45px;
  width: 75px;
  padding: 0 15px;
}
.portfolio-entry .portfolio-entry-main .project-description .big-quote >svg:nth-child(1) {
  left: 8%;
  top: 0px;
  -webkit-transform: translate(-50%, -50%) rotate(180deg);
  -moz-transform: translate(-50%, -50%) rotate(180deg);
  -o-transform: translate(-50%, -50%) rotate(180deg);
  -ms-transform: translate(-50%, -50%) rotate(180deg);
  transform: translate(-50%, -50%) rotate(180deg);
}
.portfolio-entry .portfolio-entry-main .project-description .big-quote >svg:nth-child(2) {
  left: 92%;
  bottom: 0px;
  -webkit-transform: translate(-50%, 50%);
  -moz-transform: translate(-50%, 50%);
  -o-transform: translate(-50%, 50%);
  -ms-transform: translate(-50%, 50%);
  transform: translate(-50%, 50%);
}
.portfolio-entry .portfolio-entry-main .project-description .big-quote a.img-link {
  display: inline-block;
  max-width: 200px;
  margin: 0;
  height: 100%;
  float: left;
  z-index: 20;
  position: relative;
}
.portfolio-entry .portfolio-entry-main .project-description .big-quote a.img-link img {
  max-width: 175px;
  max-height: 175px;
  margin-right: 35px;
  border-radius: 5px;
}
.portfolio-entry .portfolio-entry-main .project-description .big-quote a.img-link .pdf-icon {
  position: absolute;
  top: 0;
  right: 0;
  width: 32px;
  height: 32px;
  -webkit-transform: translate(-120%, 20%);
  -moz-transform: translate(-120%, 20%);
  -o-transform: translate(-120%, 20%);
  -ms-transform: translate(-120%, 20%);
  transform: translate(-120%, 20%);
}
.portfolio-entry .portfolio-entry-main .project-description .big-quote a.img-link .pdf-icon svg {
  width: 32px;
  height: 32px;
}
.portfolio-entry .portfolio-entry-main .project-description .big-quote a.img-link:hover img {
  -webkit-box-shadow: 0 0 0 2px rgba(165,97,181,0.21);
  box-shadow: 0 0 0 2px rgba(165,97,181,0.21);
}
.portfolio-entry .portfolio-entry-main .project-description .big-quote a.img-link:after {
  content: "";
  clear: both;
  display: table;
}
.portfolio-entry .portfolio-entry-main .project-description .big-quote a.img-link p {
  color: #888;
  margin: 0;
}
