.hamburger {
  z-index: 102;
  position: fixed;
  background-color: transparent;
  top: 17px;
  display: none;
  right: 27px;
  width: 45px;
  height: 55px;
  cursor: pointer;
  -webkit-transition: 0.5s ease-in-out;
  -webkit-transition: 0.5s ease-in-out;
  -moz-transition: 0.5s ease-in-out;
  -o-transition: 0.5s ease-in-out;
  -ms-transition: 0.5s ease-in-out;
  transition: 0.5s ease-in-out;
  -webkit-transform: rotate(0);
  -ms-transform: rotate(0);
  -webkit-transform: rotate(0);
  -moz-transform: rotate(0);
  -o-transform: rotate(0);
  -ms-transform: rotate(0);
  transform: rotate(0);
}
@media (max-width: 1200px) {
  .hamburger {
    display: block;
  }
}
.hamburger:hover div {
  background: #864696;
}
.hamburger div {
  position: absolute;
  left: 0;
  background: #a561b5;
  width: 100%;
  height: 3px;
  -webkit-transition: 0.25s ease-in-out;
  -webkit-transition: 0.25s ease-in-out;
  -moz-transition: 0.25s ease-in-out;
  -o-transition: 0.25s ease-in-out;
  -ms-transition: 0.25s ease-in-out;
  transition: 0.25s ease-in-out;
  -webkit-transform: rotate(0);
  -ms-transform: rotate(0);
  -webkit-transform: rotate(0);
  -moz-transform: rotate(0);
  -o-transform: rotate(0);
  -ms-transform: rotate(0);
  transform: rotate(0);
}
.hamburger div:nth-child(1) {
  top: 6px;
}
.hamburger div:nth-child(2),
.hamburger div:nth-child(3) {
  top: 21px;
}
.hamburger div:nth-child(4) {
  top: 36px;
}
.hamburger.open div:nth-child(1),
.hamburger.open div:nth-child(4) {
  top: 21px;
  left: 50%;
  width: 0%;
  opacity: 0;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  filter: alpha(opacity=0);
}
.hamburger.open div:nth-child(2) {
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
  width: 70%;
}
.hamburger.open div:nth-child(3) {
  -webkit-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  transform: rotate(-45deg);
  width: 70%;
}
