#gallery-alternate {
  margin-top: 100px;
}
.swiper-team.swiper-container .swiper-pagination {
  bottom: 0;
}
#team-photo-section {
  margin: 55px -10px 40px -10px;
}
#team-photo-section .gallery-people {
  overflow: hidden;
  padding-bottom: 55px;
}
#team-photo-section .gallery-people .team-photo-container {
  overflow: hidden;
}
#team-photo-section .gallery-people .team-photo-container .team-photo {
  margin: 9px;
  position: relative;
  overflow: hidden;
  border-radius: 5px;
}
#team-photo-section .gallery-people .team-photo-container .team-photo:after {
  content: "";
  position: absolute;
  display: block;
  z-index: 21;
  width: 100%;
  height: 20px;
  background-color: #fff;
  bottom: -20px;
  left: 0;
}
#team-photo-section .gallery-people .team-photo-container .team-photo img {
  width: 100%;
}
#team-photo-section .gallery-people .team-photo-container .team-photo .person-name {
  color: #fff;
  background-color: rgba(134,70,150,0);
  -webkit-box-shadow: 0 0 0 0 #fff;
  box-shadow: 0 0 0 0 #fff;
  padding: 4px 8px;
  font-size: 15px;
  margin: 0;
  position: absolute;
  bottom: 0px;
  left: 0;
  -webkit-transform: translateY(120%);
  -moz-transform: translateY(120%);
  -o-transform: translateY(120%);
  -ms-transform: translateY(120%);
  transform: translateY(120%);
  -webkit-transition: 0.35s all;
  -moz-transition: 0.35s all;
  -o-transition: 0.35s all;
  -ms-transition: 0.35s all;
  transition: 0.35s all;
  border-bottom-left-radius: 5px;
  border-top-right-radius: 5px;
}
#team-photo-section .gallery-people .team-photo-container .team-photo:hover .person-name {
  -webkit-transform: translateY(0%);
  -moz-transform: translateY(0%);
  -o-transform: translateY(0%);
  -ms-transform: translateY(0%);
  transform: translateY(0%);
  background-color: rgba(134,70,150,0.85);
}
