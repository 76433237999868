#careers {
  overflow-x: hidden;
}
#careers h1 {
  margin: 0 0 30px 0;
  font-size: 52px;
  color: #3c2a41;
}
@media (max-width: 1600px) {
  #careers h1 {
    font-size: 45px;
  }
}
@media (max-width: 992px) {
  #careers h1 {
    font-size: 38px;
  }
}
#careers h2 {
  margin: 0 0 30px 0;
  font-size: 48px;
  color: #3c2a41;
}
@media (max-width: 1600px) {
  #careers h2 {
    font-size: 38px;
  }
}
@media (max-width: 992px) {
  #careers h2 {
    font-size: 35px;
  }
}
#careers section {
  padding: 120px 200px;
  margin: 0;
}
@media (max-width: 1600px) {
  #careers section {
    padding: 120px 100px;
  }
}
@media (max-width: 992px) {
  #careers section {
    padding: 80px;
  }
}
@media (max-width: 767px) {
  #careers section {
    padding: 60px;
  }
}
#careers a.primary {
  font-size: 24px;
}
#careers a.primary svg {
  width: 30px;
  height: 30px;
}
#careers section.cover {
  background-image: url(/lib/6_TFhEvJ.jpg);
  -webkit-background-size: cover;
  -moz-background-size: cover;
  background-size: cover;
  background-position: center;
  height: 85vh;
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-orient: vertical;
  -moz-box-orient: vertical;
  -o-box-orient: vertical;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: justify;
  -moz-box-pack: justify;
  -o-box-pack: justify;
  -ms-flex-pack: justify;
  -webkit-justify-content: space-between;
  justify-content: space-between;
  -webkit-box-align: start;
  -moz-box-align: start;
  -o-box-align: start;
  -ms-flex-align: start;
  -webkit-align-items: start;
  align-items: start;
}
@media (max-width: 992px) {
  #careers section.cover {
    padding-top: 120px;
  }
}
@media (max-width: 767px) {
  #careers section.cover {
    background-position: 30% 50%;
  }
}
#careers section.cover .cover-heading-small {
  font-size: 35px;
  font-weight: 300;
  color: #fff;
}
@media (max-width: 767px) {
  #careers section.cover .cover-heading-small {
    font-size: 30px;
  }
}
#careers section.cover .cover-heading-big {
  padding-top: 15px;
  padding-bottom: 15px;
  font-size: 75px;
  font-weight: 900;
  color: #fff;
}
@media (max-width: 992px) {
  #careers section.cover .cover-heading-big {
    font-size: 65px;
  }
}
@media (max-width: 767px) {
  #careers section.cover .cover-heading-big {
    font-size: 55px;
  }
}
#careers section.cover a.primary {
  background-color: #75ab4f;
  color: #fff;
  font-weight: 600;
  margin-right: 45px;
}
#careers section.cover a.primary svg {
  fill: #fff;
}
#careers section.cover a.primary.secondary {
  color: #6d3979;
  background-color: #e5d2e6;
}
#careers section.cover a.primary.secondary svg {
  fill: #6d3979;
}
#careers section.tldr {
  background-image: url(/lib/1dv6-jv0.png);
  -webkit-background-size: cover;
  -moz-background-size: cover;
  background-size: cover;
  background-position: right;
}
@media (max-width: 992px) {
  #careers section.tldr {
    background-image: none;
    background: rgba(165,97,181,0.1);
  }
}
#careers section.tldr p {
  width: 40vw;
  font-size: 22px;
  margin-bottom: 0;
}
@media (max-width: 1600px) {
  #careers section.tldr p {
    font-size: 20px;
  }
}
@media (max-width: 992px) {
  #careers section.tldr p {
    width: auto;
  }
}
#careers section.mainstory {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-orient: vertical;
  -moz-box-orient: vertical;
  -o-box-orient: vertical;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  padding: 120px 0;
}
@media (max-width: 992px) {
  #careers section.mainstory {
    padding: 80px 0;
  }
}
@media (max-width: 767px) {
  #careers section.mainstory {
    padding: 60px 0;
  }
}
#careers section.mainstory .mainstory-segment {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-align: center;
  -moz-box-align: center;
  -o-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  -webkit-box-pack: start;
  -moz-box-pack: start;
  -o-box-pack: start;
  -ms-flex-pack: start;
  -webkit-justify-content: flex-start;
  justify-content: flex-start;
  margin-bottom: 140px;
}
@media (max-width: 1600px) {
  #careers section.mainstory .mainstory-segment {
    margin-bottom: 100px;
  }
}
@media (max-width: 1200px) {
  #careers section.mainstory .mainstory-segment {
    display: block;
  }
}
@media (max-width: 992px) {
  #careers section.mainstory .mainstory-segment {
    margin-bottom: 60px;
  }
}
#careers section.mainstory .mainstory-segment p {
  font-weight: 400;
  font-size: 22px;
}
@media (max-width: 1600px) {
  #careers section.mainstory .mainstory-segment p {
    font-size: 20px;
  }
}
#careers section.mainstory .mainstory-segment:last-child {
  margin-bottom: 20px;
}
@media (max-width: 1600px) {
  #careers section.mainstory .mainstory-segment:last-child {
    margin-bottom: 0;
  }
}
#careers section.mainstory .mainstory-description {
  width: 40%;
}
@media (max-width: 1200px) {
  #careers section.mainstory .mainstory-description {
    width: auto;
    margin-bottom: 50px;
  }
}
#careers section.mainstory .mainstory-description p:last-child {
  margin-bottom: 0;
}
#careers section.mainstory .mainstory-quotes {
  background-color: rgba(165,97,181,0.1);
  width: 60%;
  position: relative;
}
@media (max-width: 1200px) {
  #careers section.mainstory .mainstory-quotes {
    width: auto;
    border-radius: 0;
  }
}
#careers section.mainstory .mainstory-quotes .quote-img {
  position: absolute;
  top: -80px;
  left: 50%;
  -webkit-transform: translateX(-50%);
  -moz-transform: translateX(-50%);
  -o-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
}
@media (max-width: 1200px) {
  #careers section.mainstory .mainstory-quotes .quote-img {
    top: -45px;
  }
}
#careers section.mainstory .mainstory-quotes .quote-img svg {
  fill: #e5d2e6;
  width: 160px;
  height: 160px;
}
@media (max-width: 1200px) {
  #careers section.mainstory .mainstory-quotes .quote-img svg {
    width: 100px;
    height: 100px;
  }
}
#careers section.mainstory .mainstory-quotes .mainstory-quote {
  margin-bottom: 30px;
}
#careers section.mainstory .mainstory-quotes .mainstory-quote:last-child {
  margin-bottom: 0;
}
#careers section.mainstory .mainstory-quotes .mainstory-quote p {
  font-size: 20px;
  font-style: italic;
  font-weight: 300;
  color: #3c2a41;
  margin: 0 0 5px 0;
}
@media (max-width: 1600px) {
  #careers section.mainstory .mainstory-quotes .mainstory-quote p {
    font-size: 18px;
  }
}
@media (max-width: 1200px) {
  #careers section.mainstory .mainstory-quotes .mainstory-quote p {
    font-size: 20px;
  }
}
#careers section.mainstory .mainstory-quotes .mainstory-quote p.author {
  text-align: right;
  font-style: normal;
  font-weight: 600;
  color: #6d3979;
}
#careers .mainstory-segment-right .mainstory-description {
  margin-left: 200px;
  margin-right: 100px;
}
@media (max-width: 1600px) {
  #careers .mainstory-segment-right .mainstory-description {
    margin-left: 100px;
    margin-right: 80px;
  }
}
@media (max-width: 992px) {
  #careers .mainstory-segment-right .mainstory-description {
    margin-left: 80px;
  }
}
@media (max-width: 767px) {
  #careers .mainstory-segment-right .mainstory-description {
    margin-left: 60px;
    margin-right: 60px;
  }
}
#careers .mainstory-segment-right .mainstory-quotes {
  padding: 80px 200px 80px 100px;
  border-radius: 15px 0 0 15px;
}
@media (max-width: 1600px) {
  #careers .mainstory-segment-right .mainstory-quotes {
    padding: 80px;
  }
}
@media (max-width: 767px) {
  #careers .mainstory-segment-right .mainstory-quotes {
    padding: 60px;
  }
}
#careers .mainstory-segment-right .quote-img svg {
  margin-right: 100px;
}
@media (max-width: 1600px) {
  #careers .mainstory-segment-right .quote-img svg {
    margin-right: 0;
  }
}
#careers .mainstory-segment-left {
  -webkit-box-direction: reverse;
  -moz-box-direction: reverse;
  -o-box-direction: reverse;
  -webkit-box-orient: horizontal;
  -moz-box-orient: horizontal;
  -o-box-orient: horizontal;
  -webkit-flex-direction: row-reverse;
  -ms-flex-direction: row-reverse;
  flex-direction: row-reverse;
}
#careers .mainstory-segment-left .mainstory-description {
  margin-right: 200px;
  margin-left: 100px;
}
@media (max-width: 1600px) {
  #careers .mainstory-segment-left .mainstory-description {
    margin-right: 100px;
    margin-left: 80px;
  }
}
@media (max-width: 992px) {
  #careers .mainstory-segment-left .mainstory-description {
    margin-right: 80px;
  }
}
@media (max-width: 767px) {
  #careers .mainstory-segment-left .mainstory-description {
    margin-right: 60px;
    margin-left: 60px;
  }
}
#careers .mainstory-segment-left .mainstory-quotes {
  padding: 80px 100px 80px 200px;
  border-radius: 0 15px 15px 0;
}
@media (max-width: 1600px) {
  #careers .mainstory-segment-left .mainstory-quotes {
    padding: 80px;
  }
}
@media (max-width: 767px) {
  #careers .mainstory-segment-left .mainstory-quotes {
    padding: 60px;
  }
}
#careers .mainstory-segment-left .quote-img svg {
  margin-left: 100px;
}
@media (max-width: 1600px) {
  #careers .mainstory-segment-left .quote-img svg {
    margin-left: 0;
  }
}
#careers section.quotes {
  background-image: url(/lib/1F1l42qF.png);
  -webkit-background-size: cover;
  -moz-background-size: cover;
  background-size: cover;
  background-position: center;
  background-color: rgba(165,97,181,0.1);
  text-align: center;
  padding: 120px 200px;
}
@media (max-width: 1600px) {
  #careers section.quotes {
    padding: 100px 100px;
  }
}
@media (max-width: 1200px) {
  #careers section.quotes {
    padding: 100px 15px;
  }
}
@media (max-width: 992px) {
  #careers section.quotes {
    padding: 80px;
  }
}
@media (max-width: 767px) {
  #careers section.quotes {
    padding: 60px;
  }
}
@media (max-width: 992px) {
  #careers section.quotes .swiper-wrapper {
    margin-bottom: 35px;
  }
}
#careers section.quotes .swiper-slide {
  -webkit-align-self: center;
  align-self: center;
  -ms-flex-item-align: center;
  margin-bottom: 80px;
  opacity: 0;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  filter: alpha(opacity=0);
  -webkit-transition: 0.25s all;
  -moz-transition: 0.25s all;
  -o-transition: 0.25s all;
  -ms-transition: 0.25s all;
  transition: 0.25s all;
}
@media (max-width: 992px) {
  #careers section.quotes .swiper-slide {
    margin-bottom: 40px;
  }
}
#careers section.quotes .swiper-slide-active {
  opacity: 1;
  -ms-filter: none;
  filter: none;
}
#careers section.quotes h1 {
  margin-bottom: 60px;
}
@media (max-width: 1600px) {
  #careers section.quotes h1 {
    margin-bottom: 50px;
  }
}
@media (max-width: 992px) {
  #careers section.quotes h1 {
    margin-bottom: 40px;
  }
}
#careers section.quotes .swiper-button {
  opacity: 1;
  -ms-filter: none;
  filter: none;
  width: 100px;
  height: 100px;
  border-radius: 5px;
  -webkit-transition: background-color 0.15s;
  -moz-transition: background-color 0.15s;
  -o-transition: background-color 0.15s;
  -ms-transition: background-color 0.15s;
  transition: background-color 0.15s;
}
@media (max-width: 1200px) {
  #careers section.quotes .swiper-button {
    width: 75px;
    height: 75px;
  }
}
@media (max-width: 992px) {
  #careers section.quotes .swiper-button {
    display: none;
  }
}
#careers section.quotes .swiper-button.swiper-button-disabled {
  opacity: 0.35;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=35)";
  filter: alpha(opacity=35);
}
#careers section.quotes .swiper-button.swiper-button-disabled:hover {
  background-color: transparent;
}
#careers section.quotes .swiper-button:hover {
  background-color: rgba(165,97,181,0.1);
}
#careers section.quotes .swiper-button-prev,
#careers section.quotes .swiper-button-next {
  top: 25%;
}
#careers section.quotes .swiper-button-prev {
  left: 0;
  -webkit-background-size: contain;
  -moz-background-size: contain;
  background-size: contain;
  background-image: url(/lib/3rtQsER5.svg);
}
#careers section.quotes .swiper-button-next {
  right: 0;
  -webkit-background-size: contain;
  -moz-background-size: contain;
  background-size: contain;
  background-image: url(/lib/118j41IN.svg);
}
#careers section.quotes .quote {
  width: 50vw;
  font-size: 24px;
  margin: 0 auto;
}
@media (max-width: 1600px) {
  #careers section.quotes .quote {
    font-size: 22px;
  }
}
@media (max-width: 1200px) {
  #careers section.quotes .quote {
    width: 75vw;
  }
}
@media (max-width: 992px) {
  #careers section.quotes .quote {
    width: auto;
  }
}
#careers section.quotes .quote-text {
  color: #3c2a41;
  font-style: italic;
  font-weight: 300;
}
#careers section.quotes .quote-author {
  color: #6d3979;
  font-weight: 600;
}
#careers .swiper-container .swiper-pagination.swiper-pagination-bullets .swiper-pagination-bullet {
  width: 32px;
  height: 32px;
  margin: 0 24px;
}
@media (max-width: 1200px) {
  #careers .swiper-container .swiper-pagination.swiper-pagination-bullets .swiper-pagination-bullet {
    margin: 0 20px;
  }
}
@media (max-width: 992px) {
  #careers .swiper-container .swiper-pagination.swiper-pagination-bullets .swiper-pagination-bullet {
    width: 24px;
    height: 24px;
    margin: 0 16px;
  }
}
@media (max-width: 767px) {
  #careers .swiper-container .swiper-pagination.swiper-pagination-bullets .swiper-pagination-bullet {
    margin: 0 10px;
    width: 16px;
    height: 16px;
  }
}
#careers section.projects {
  padding-bottom: 0;
}
#careers section.projects h1 {
  margin-bottom: 60px;
}
@media (max-width: 1600px) {
  #careers section.projects h1 {
    margin-bottom: 50px;
  }
}
#careers section.projects .projects-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  -webkit-column-gap: 60px;
  -moz-column-gap: 60px;
  column-gap: 60px;
  row-gap: 60px;
}
@media (max-width: 1200px) {
  #careers section.projects .projects-grid {
    grid-template-columns: 1fr;
  }
}
#careers section.projects .projects-grid .project-box {
  border: 1px solid #e5d2e6;
  border-radius: 5px;
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-orient: vertical;
  -moz-box-orient: vertical;
  -o-box-orient: vertical;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
}
#careers section.projects .projects-grid .project-box .project-heading {
  background: rgba(165,97,181,0.1);
  border-bottom: 1px solid #e5d2e6;
  padding: 30px;
  -webkit-box-flex: 2;
  -moz-box-flex: 2;
  -o-box-flex: 2;
  -ms-box-flex: 2;
  box-flex: 2;
  -webkit-flex-grow: 2;
  flex-grow: 2;
}
#careers section.projects .projects-grid .project-box .project-heading h4 {
  font-size: 28px;
  line-height: 130%;
  margin-top: 0;
}
@media (max-width: 1600px) {
  #careers section.projects .projects-grid .project-box .project-heading h4 {
    font-size: 24px;
  }
}
#careers section.projects .projects-grid .project-box .project-heading p {
  margin-bottom: 0;
  font-size: 20px;
}
@media (max-width: 1600px) {
  #careers section.projects .projects-grid .project-box .project-heading p {
    font-size: 18px;
  }
}
#careers section.projects .projects-grid .project-box .project-technologies {
  padding: 0 15px;
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-pack: space-evenly;
  -moz-box-pack: space-evenly;
  -o-box-pack: space-evenly;
  -ms-flex-pack: space-evenly;
  -webkit-justify-content: space-evenly;
  justify-content: space-evenly;
}
@media (max-width: 767px) {
  #careers section.projects .projects-grid .project-box .project-technologies {
    -webkit-box-lines: multiple;
    -moz-box-lines: multiple;
    -o-box-lines: multiple;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
}
#careers section.projects .projects-grid .project-box .project-technologies .project-tech {
  max-width: 120px;
  width: 100%;
  height: 120px;
  padding: 10px;
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-pack: center;
  -moz-box-pack: center;
  -o-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -moz-box-align: center;
  -o-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}
@media (max-width: 767px) {
  #careers section.projects .projects-grid .project-box .project-technologies .project-tech {
    margin-right: 0;
  }
}
#careers section.projects .projects-grid .project-box .project-technologies .project-tech img {
  max-width: 100%;
  max-height: 100%;
}
@media (max-width: 767px) {
  #careers section.jobs {
    padding-right: 0;
    padding-left: 0;
  }
}
#careers section.jobs h1 {
  margin-bottom: 60px;
}
@media (max-width: 1600px) {
  #careers section.jobs h1 {
    margin-bottom: 50px;
  }
}
@media (max-width: 767px) {
  #careers section.jobs h1 {
    margin-left: 60px;
  }
}
#careers section.jobs #jobsAccordion.panel-group {
  margin-top: 32px;
  margin-bottom: 0;
  border-radius: 5px;
  overflow: hidden;
}
@media (max-width: 767px) {
  #careers section.jobs #jobsAccordion.panel-group {
    border-radius: 0;
  }
}
#careers section.jobs #jobsAccordion.panel-group .panel-body {
  border: none;
  padding: 35px 50px;
  background: rgba(255,255,255,0.45);
}
@media (max-width: 992px) {
  #careers section.jobs #jobsAccordion.panel-group .panel-body {
    padding: 35px 40px;
  }
}
#careers section.jobs #jobsAccordion.panel-group .panel {
  margin: 0;
  border-bottom: 1px solid rgba(165,97,181,0.27);
  border-top: none;
  border-left: none;
  border-right: none;
  background: rgba(165,97,181,0.16);
  -webkit-box-shadow: none;
  box-shadow: none;
}
#careers section.jobs #jobsAccordion.panel-group .panel:last-child {
  border-bottom: none;
}
#careers section.jobs #jobsAccordion.panel-group .panel a.job-panel-button {
  background-color: rgba(165,97,181,0.27);
  width: 100%;
  display: block;
  padding: 0 50px;
}
@media (max-width: 992px) {
  #careers section.jobs #jobsAccordion.panel-group .panel a.job-panel-button {
    padding: 0 35px;
  }
}
@media (max-width: 767px) {
  #careers section.jobs #jobsAccordion.panel-group .panel a.job-panel-button {
    padding: 0 60px;
  }
}
#careers section.jobs #jobsAccordion.panel-group .panel a.job-panel-button.collapsed {
  background-color: transparent;
}
#careers section.jobs #jobsAccordion.panel-group .panel a.job-panel-button.collapsed p {
  color: #864696;
}
#careers section.jobs #jobsAccordion.panel-group .panel a.job-panel-button.collapsed:hover {
  background-color: rgba(165,97,181,0.21);
  color: #864696;
}
#careers section.jobs #jobsAccordion.panel-group .panel a.job-panel-button.collapsed .job-arrow {
  -webkit-transform: rotate3d(1, 0, 0, 0deg);
  -moz-transform: rotate3d(1, 0, 0, 0deg);
  -o-transform: rotate3d(1, 0, 0, 0deg);
  -ms-transform: rotate3d(1, 0, 0, 0deg);
  transform: rotate3d(1, 0, 0, 0deg);
}
#careers section.jobs #jobsAccordion.panel-group .panel a.job-panel-button.collapsed .job-arrow polygon {
  fill: #6d3979;
}
#careers section.jobs #jobsAccordion.panel-group .panel a.job-panel-button p {
  margin: 0;
  text-transform: uppercase;
  font-family: "Nunito Sans";
  color: #6d3979;
  font-weight: 700;
  padding: 30px 0;
  font-size: 24px;
  letter-spacing: 0.5px;
  -webkit-transition: 0.35s all;
  -moz-transition: 0.35s all;
  -o-transition: 0.35s all;
  -ms-transition: 0.35s all;
  transition: 0.35s all;
}
@media (max-width: 1600px) {
  #careers section.jobs #jobsAccordion.panel-group .panel a.job-panel-button p {
    font-size: 22px;
  }
}
@media (max-width: 992px) {
  #careers section.jobs #jobsAccordion.panel-group .panel a.job-panel-button p {
    font-size: 20px;
  }
}
#careers section.jobs #jobsAccordion.panel-group .panel a.job-panel-button .job-arrow {
  width: 24px;
  fill: #864696;
  float: right;
  margin-top: 35px;
  -webkit-transform: rotate3d(1, 0, 0, 180deg);
  -moz-transform: rotate3d(1, 0, 0, 180deg);
  -o-transform: rotate3d(1, 0, 0, 180deg);
  -ms-transform: rotate3d(1, 0, 0, 180deg);
  transform: rotate3d(1, 0, 0, 180deg);
  -webkit-transition: 0.35s all;
  -moz-transition: 0.35s all;
  -o-transition: 0.35s all;
  -ms-transition: 0.35s all;
  transition: 0.35s all;
  -webkit-transform-origin: center center;
  -moz-transform-origin: center center;
  -o-transform-origin: center center;
  -ms-transform-origin: center center;
  transform-origin: center center;
}
#careers section.jobs #jobsAccordion.panel-group .panel .panel-heading {
  padding: 0;
  border: none;
  background-color: transparent;
  -webkit-transition: 0.35s all;
  -moz-transition: 0.35s all;
  -o-transition: 0.35s all;
  -ms-transition: 0.35s all;
  transition: 0.35s all;
}
#careers section.jobs #jobsAccordion.panel-group .job-content {
  padding: 0 35px;
  border-left: 2px solid rgba(165,97,181,0.16);
}
@media (max-width: 767px) {
  #careers section.jobs #jobsAccordion.panel-group .job-content {
    border: none;
    padding: 0 25px;
  }
}
#careers section.jobs #jobsAccordion.panel-group p {
  color: #3f3f3f;
  font-size: 22px;
}
@media (max-width: 992px) {
  #careers section.jobs #jobsAccordion.panel-group p {
    font-size: 20px;
  }
}
#careers section.jobs #jobsAccordion.panel-group p.small {
  font-size: 16px;
}
#careers section.jobs #jobsAccordion.panel-group p.jobheading {
  font-family: 'Noto Sans';
  margin-bottom: 12px;
  color: #3c2a41;
  font-size: 24px;
}
@media (max-width: 992px) {
  #careers section.jobs #jobsAccordion.panel-group p.jobheading {
    font-size: 22px;
  }
}
#careers section.jobs #jobsAccordion.panel-group ul {
  padding-left: 25px;
  font-size: 22px;
  color: #a561b5;
}
#careers section.jobs #jobsAccordion.panel-group ul li p {
  margin: 5px 0 3px -2px;
}
#careers section.jobs #jobsAccordion.panel-group .job-button {
  text-align: left;
}
#careers section.jobs #jobsAccordion.panel-group .job-button a.primary {
  font-size: 24px;
}
#careers section.jobs #jobsAccordion.panel-group .job-button a.primary svg {
  width: 30px;
  height: 30px;
}
#careers section.process {
  background-color: rgba(165,97,181,0.1);
}
#careers section.process .graph-desktop,
#careers section.process .graph-mobile {
  width: 100%;
}
#careers section.process .graph-mobile {
  display: none;
}
@media (max-width: 1200px) {
  #careers section.process .graph-mobile {
    display: block;
  }
}
#careers section.process .graph-desktop {
  display: block;
}
@media (max-width: 1200px) {
  #careers section.process .graph-desktop {
    display: none;
  }
}
#careers section.cta {
  background-image: url(/lib/1BnUpwnA.jpg);
  -webkit-background-size: cover;
  -moz-background-size: cover;
  background-size: cover;
  background-position: center;
}
#careers section.cta h1 {
  color: #fff;
}
#careers section.cta p {
  color: #fff;
  font-size: 28px;
}
@media (max-width: 1600px) {
  #careers section.cta p {
    font-size: 24px;
  }
}
#careers section.cta a.primary {
  background-color: #75ab4f;
  color: #fff;
}
#careers section.cta a.primary.light {
  background-color: #e5d2e6;
  color: #6d3979;
}
#careers section.cta a.primary svg {
  fill: #fff;
}
#careers section.cta .cta-cards {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  margin: 105px 0 70px 0;
}
@media (max-width: 767px) {
  #careers section.cta .cta-cards {
    -webkit-box-orient: vertical;
    -moz-box-orient: vertical;
    -o-box-orient: vertical;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }
}
#careers section.cta .cta-cards .cta-card {
  width: 30%;
  margin-right: 100px;
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-orient: vertical;
  -moz-box-orient: vertical;
  -o-box-orient: vertical;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: justify;
  -moz-box-pack: justify;
  -o-box-pack: justify;
  -ms-flex-pack: justify;
  -webkit-justify-content: space-between;
  justify-content: space-between;
  -webkit-box-align: start;
  -moz-box-align: start;
  -o-box-align: start;
  -ms-flex-align: start;
  -webkit-align-items: flex-start;
  align-items: flex-start;
}
@media (max-width: 992px) {
  #careers section.cta .cta-cards .cta-card {
    width: 50%;
    margin-right: 60px;
  }
}
@media (max-width: 767px) {
  #careers section.cta .cta-cards .cta-card {
    width: 100%;
    margin-right: 0;
    margin-bottom: 50px;
  }
}
#careers section.cta .cta-cards .cta-card:last-child {
  margin-right: 0;
}
