ul.touk-tabs.technology li {
  width: 16.66%;
}
ul.touk-tabs.conference-tabs {
  margin-top: 30px;
}
ul.touk-tabs.conference-tabs li {
  width: 50%;
}
ul.touk-tabs {
  list-style: none;
  padding: 0;
}
ul.touk-tabs li {
  display: inline-block;
  width: 25%;
  padding: 0;
  margin: 0;
  position: relative;
  border-right: 1px solid rgba(165,97,181,0.27);
}
ul.touk-tabs li:last-child {
  border-right: 0;
}
ul.touk-tabs li.active a {
  background-color: rgba(165,97,181,0.27);
  font-weight: 700;
  color: #6d3979;
}
ul.touk-tabs li.active a:hover {
  background-color: rgba(165,97,181,0.27);
}
ul.touk-tabs li a {
  width: 100%;
  padding: 12px 0;
  display: block;
  text-align: center;
  background-color: rgba(165,97,181,0.16);
  color: #864696;
  font-size: 20px;
}
@media (max-width: 767px) {
  ul.touk-tabs li a {
    padding: 12px 0;
    font-size: 20px;
  }
}
ul.touk-tabs li a:hover {
  background-color: rgba(165,97,181,0.21);
  color: #6d3979;
}
ul.touk-tabs li:first-child a {
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}
ul.touk-tabs li:last-child a {
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}
#technologyAccordion {
  border-radius: 5px;
  overflow: hidden;
}
#technologyAccordion .panel.panel-default {
  margin: 0;
  border: none;
  border-color: transparent;
  -webkit-border-image: none;
  -moz-border-image: none;
  -o-border-image: none;
  border-image: none;
}
#technologyAccordion .panel.panel-default:last-child .panel-heading {
  border: none;
}
#technologyAccordion .panel.panel-default .panel-heading {
  padding: 0;
  border-top: 0;
  border-left: 0;
  border-right: 0;
  border-bottom: 1px solid rgba(165,97,181,0.16);
}
#technologyAccordion .panel.panel-default .panel-heading a {
  display: block;
  width: 100%;
  height: 100%;
  padding: 15px 0;
  background: rgba(165,97,181,0.27);
  color: #6d3979;
  font-weight: 700;
}
#technologyAccordion .panel.panel-default .panel-heading a.collapsed {
  background: rgba(165,97,181,0.1);
  color: #864696;
  font-weight: 400;
}
#technologyAccordion .panel.panel-default .panel-heading a.collapsed:hover {
  background: rgba(165,97,181,0.16);
}
#technologyAccordion .panel.panel-default .panel-heading a:hover {
  background: rgba(165,97,181,0.27);
}
#technologyAccordion .panel-body {
  border: none;
  padding: 15px 15px 0 15px;
}
