::selection {
  background: transparent;
}
#talk-previews {
  margin: 35px 0;
  padding-bottom: 67px;
}
#talk-previews .swiper-pagination {
  bottom: 7px;
}
#talk-previews .swiper-button {
  -webkit-transform: translateY(-70%);
  -moz-transform: translateY(-70%);
  -o-transform: translateY(-70%);
  -ms-transform: translateY(-70%);
  transform: translateY(-70%);
}
@media (max-width: 766px) {
  #talk-previews .swiper-button {
    display: none;
  }
}
.video-preview {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  display: inline-block;
}
.youtube-player-container {
  max-width: 501px;
  margin: 25px auto 0;
  border-radius: 5px;
  overflow: hidden;
}
@media (max-width: 768px) {
  .youtube-player-container {
    max-width: 480px;
    padding: 0 20px;
  }
}
.youtube-player-container .youtube-player {
  position: relative;
  padding-bottom: 56.23%;
  height: 0;
  overflow: hidden;
  max-width: 100%;
  background: #000;
}
.youtube-player-container .youtube-player:hover .play {
  opacity: 0.95;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=95)";
  filter: alpha(opacity=95);
}
.youtube-player-container .youtube-player iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 100;
  background: transparent;
}
.youtube-player-container .youtube-player img {
  bottom: 0;
  display: block;
  left: 0;
  margin: auto;
  max-width: 100%;
  width: 100%;
  position: absolute;
  right: 0;
  top: 0;
  border: none;
  height: auto;
  cursor: pointer;
  -webkit-transition: 0.4s all;
  -moz-transition: 0.4s all;
  -webkit-transition: 0.4s all;
  -moz-transition: 0.4s all;
  -o-transition: 0.4s all;
  -ms-transition: 0.4s all;
  transition: 0.4s all;
}
.youtube-player-container .youtube-player .play {
  height: 72px;
  width: 72px;
  left: 50%;
  top: 50%;
  margin-left: -36px;
  margin-top: -36px;
  position: absolute;
  background: url(/lib/2TSyuipv.png) no-repeat;
  -webkit-background-size: contain;
  -moz-background-size: contain;
  background-size: contain;
  cursor: pointer;
  opacity: 0.8;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=80)";
  filter: alpha(opacity=80);
  -webkit-transition: 0.25s all;
  -moz-transition: 0.25s all;
  -o-transition: 0.25s all;
  -ms-transition: 0.25s all;
  transition: 0.25s all;
}
.talks-link {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  min-height: 305px;
  margin: 0 80px;
  -webkit-box-pack: center;
  -moz-box-pack: center;
  -o-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -moz-box-align: center;
  -o-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}
@media (max-width: 768px) {
  .talks-link {
    margin: 0 35px;
  }
}
.talks-link h4 {
  font-size: 26px;
  color: #a561b5;
  margin-top: 0;
}
@media (max-width: 768px) {
  .talks-link h4 {
    font-size: 24px;
  }
}
.talks-link p {
  font-size: 20px;
}
@media (max-width: 768px) {
  .talks-link p {
    font-size: 18px;
    margin: 0;
  }
}
.talks-link .talks-link-left {
  display: inline-block;
  vertical-align: middle;
}
.talks-link .talks-link-right {
  margin-left: 25px;
  display: inline-block;
  vertical-align: middle;
}
@media (max-width: 992px) {
  .talks-link .talks-link-right {
    display: none !important;
  }
}
.conferences {
  margin-top: 50px;
}
.conferences .conference-logos .conf-logo {
  margin-top: 25px;
  text-align: center;
}
.conferences .conference-logos .conf-logo img {
  height: 75px;
}
@media (max-width: 992px) {
  .conferences .conference-logos .conf-logo img {
    height: auto;
    max-width: 140px;
  }
}
@media (max-width: 992px) {
  .conferences .tab-title-suffix {
    display: none;
  }
}
