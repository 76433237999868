@media (max-width: 992px) {
  #technology-section #technologyTabs {
    display: none;
  }
}
@media (min-width: 992px) {
  #technology-section #technologyAccordion {
    display: none;
  }
}
#technology-section .technology-logos {
  text-align: center;
  margin: 35px 0 0;
}
#technology-section .technology-logos .tech {
  margin: 15px 0;
  height: 120px;
}
@media (max-width: 991px) {
  #technology-section .technology-logos .tech {
    margin-top: 0;
  }
}
#technology-section .technology-logos .tech a {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-orient: vertical;
  -moz-box-orient: vertical;
  -o-box-orient: vertical;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -moz-box-pack: center;
  -o-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -moz-box-align: center;
  -o-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  height: 120px;
  border-radius: 5px;
}
#technology-section .technology-logos .tech a:hover {
  background-color: rgba(165,97,181,0.1);
}
#technology-section .technology-logos .tech a img {
  max-width: 150px;
  max-height: 120px;
}
#technology-section .touk-tabs.technology {
  margin-bottom: 24px;
}
@media (max-width: 991px) {
  #technology-section .touk-tabs.technology li a {
    font-size: 17px;
  }
}
@media (max-width: 766px) {
  #technology-section .touk-tabs.technology li a {
    font-size: 15px;
  }
}
