#services-text {
  margin-top: 35px;
  margin-right: 140px;
}
@media (max-width: 1700px) {
  #services-text {
    margin-right: 230px;
  }
}
@media (max-width: 1350px) {
  #services-text {
    margin-right: 0;
  }
}
#services-section {
  margin-top: 50px;
}
#services-section .services-icons {
  margin-top: 35px;
  margin-bottom: 20px;
  text-align: center;
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-lines: multiple;
  -moz-box-lines: multiple;
  -o-box-lines: multiple;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: justify;
  -moz-box-pack: justify;
  -o-box-pack: justify;
  -ms-flex-pack: justify;
  -webkit-justify-content: space-between;
  justify-content: space-between;
}
#services-section .services-icons .service-card {
  background-color: #fff;
  margin: 16px 0;
  padding: 10px 15px 20px;
  border-radius: 5px;
  width: 31%;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
@media (max-width: 1200px) {
  #services-section .services-icons .service-card {
    width: 48%;
  }
}
@media (max-width: 767px) {
  #services-section .services-icons .service-card {
    width: 100%;
  }
}
#services-section .services-icons img.services-icon {
  height: 60px;
  margin-top: 35px;
}
#services-section .services-icons p.services-description {
  font-size: 18px;
}
@media (max-width: 767px) {
  #services-section .services-icons p.services-description {
    font-size: 20px;
  }
}
#services-section .services-icons p.services-heading {
  font-size: 20px;
  font-weight: 700;
  color: #3c2a41;
  margin: 20px 0 38px;
  position: relative;
}
#services-section .services-icons p.services-heading:after {
  content: "";
  position: absolute;
  top: 46px;
  left: 50%;
  -webkit-transform: translateX(-50%);
  -moz-transform: translateX(-50%);
  -o-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
  width: 32px;
  height: 1px;
  background-color: #a561b5;
}
#services-section .portfolio-button-section {
  margin-top: 0px;
}
@media (max-width: 992px) {
  #services-section .portfolio-button-section {
    margin-top: 20px;
  }
}
