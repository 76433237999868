html body header .nav-tabs > li.active > a, html body header .nav-tabs > li.active > a:hover, html body header .nav-tabs > li.active > a:focus {
  background-color: rgba(165,97,181,0.21);
  border-top: none;
  border-left: none;
  border-right: none;
  cursor: pointer;
  color: #6d3979;
  -webkit-transition: none;
  -moz-transition: none;
  -o-transition: none;
  -ms-transition: none;
  transition: none;
}
html body header .nav-tabs > li.active {
  -webkit-transition: none;
  -moz-transition: none;
  -o-transition: none;
  -ms-transition: none;
  transition: none;
}
html body header .nav-tabs > li.active:after {
  content: "";
  display: block;
  width: 100%;
  position: absolute;
  bottom: 0;
  border-bottom: 2px solid #a561b5;
}
header.desktop {
  width: 100%;
  border-bottom: 1px solid rgba(165,97,181,0.35);
  background-color: rgba(255,255,255,0.94);
  margin: 0 -15px;
  z-index: 1000;
  height: 60px;
  position: fixed;
  -webkit-transition: 0.35s all;
  -moz-transition: 0.35s all;
  -o-transition: 0.35s all;
  -ms-transition: 0.35s all;
  transition: 0.35s all;
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-orient: horizontal;
  -moz-box-orient: horizontal;
  -o-box-orient: horizontal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: justify;
  -moz-box-pack: justify;
  -o-box-pack: justify;
  -ms-flex-pack: justify;
  -webkit-justify-content: space-between;
  justify-content: space-between;
  -webkit-box-align: center;
  -moz-box-align: center;
  -o-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  backdrop-filter: blur(2px);
}
header.desktop .main-nav-container {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-align: center;
  -moz-box-align: center;
  -o-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  height: 100%;
}
header.desktop .main-nav-container ul {
  padding: 0;
  -webkit-transition: 0.35s all;
  -moz-transition: 0.35s all;
  -o-transition: 0.35s all;
  -ms-transition: 0.35s all;
  transition: 0.35s all;
  border-bottom: none;
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-align: center;
  -moz-box-align: center;
  -o-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  height: 100%;
}
header.desktop .main-nav-container ul li {
  display: inline-block;
  -webkit-transition: 0.35s all;
  -moz-transition: 0.35s all;
  -o-transition: 0.35s all;
  -ms-transition: 0.35s all;
  transition: 0.35s all;
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-align: center;
  -moz-box-align: center;
  -o-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  height: 100%;
}
header.desktop .main-nav-container ul li.nav-break {
  display: inline-block;
  width: 1px;
  height: 100%;
  background-color: rgba(165,97,181,0.35);
  padding: 0;
}
header.desktop .main-nav-container ul li a {
  height: 100%;
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-align: center;
  -moz-box-align: center;
  -o-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  border-bottom: none;
  border-top: none;
  border-left: none;
  border-right: none;
  color: #333;
  font-size: 20px;
  padding: 0 20px;
  margin: 0;
  background-color: transparent;
  -webkit-transition: none;
  -moz-transition: none;
  -o-transition: none;
  -ms-transition: none;
  transition: none;
}
header.desktop .main-nav-container ul li a:focus {
  background-color: transparent;
}
header.desktop .main-nav-container ul li a:hover {
  background-color: rgba(165,97,181,0.16);
  color: #864696;
}
@media (max-width: 1400px) {
  header.desktop .main-nav-container ul li a {
    font-size: 18px;
    padding: 0 15px;
  }
}
header.desktop .logo img {
  height: 44px;
  margin-left: 25px;
}
header.desktop .logo a {
  height: 60px;
}
@media (max-width: 1200px) {
  header.desktop {
    display: none;
  }
}
header.mobileheader {
  height: 76px;
  position: fixed;
  background-color: #fff;
  padding: 5px;
  width: 100%;
  display: none;
  top: 0;
  left: 0;
  z-index: 100;
  border-bottom: 1px solid rgba(165,97,181,0.35);
  background-color: rgba(255,255,255,0.94);
  padding: 0;
}
header.mobileheader .logo img {
  height: 76px;
}
@media (max-width: 1200px) {
  header.mobileheader {
    display: block;
  }
}
#mobilenav {
  position: fixed;
  display: none;
  background-color: #fff;
  width: 245px;
  height: 150%;
  right: -270px;
  border-left: 1px solid rgba(165,97,181,0.35);
  -webkit-transition: 0.25s all;
  -moz-transition: 0.25s all;
  -o-transition: 0.25s all;
  -ms-transition: 0.25s all;
  transition: 0.25s all;
  z-index: 101;
}
#mobilenav ul {
  margin-top: 65px;
  padding: 0;
  margin-bottom: 0;
}
#mobilenav ul .mobilenav-divider {
  width: 60px;
  height: 3px;
  background-color: #864696;
  margin: 10px auto;
}
#mobilenav ul li {
  display: block;
  margin: 0;
  padding: 0;
}
#mobilenav ul li a {
  padding: 3px 20px;
  font-size: 24px;
  color: #333;
  font-family: 'Noto Sans';
  width: 100%;
  height: 100%;
  display: block;
  text-align: center;
  -webkit-transition: 0.15s all;
  -moz-transition: 0.15s all;
  -o-transition: 0.15s all;
  -ms-transition: 0.15s all;
  transition: 0.15s all;
}
#mobilenav ul li a:hover {
  color: #a561b5;
}
#mobilenav ul li a.active {
  color: #a561b5;
}
#mobilenav.nav-visible {
  right: 0px;
}
@media (max-width: 1200px) {
  #mobilenav {
    display: block;
  }
}
#newPostsCount {
  width: 10px;
  height: 10px;
  background-color: #64a70b;
  border-radius: 50%;
  position: absolute;
  top: 3px;
  right: -12px;
  display: none;
}
#newPostsCount.visible {
  display: initial;
}
.nav-break + .site-link {
  font-weight: 700;
  font-size: 200px;
}
