#workshops-section {
  font-size: 18px;
  margin-top: 70px;
  margin-bottom: 45px;
}
#workshops-section .workshop-wrap {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-align: center;
  -moz-box-align: center;
  -o-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  margin-bottom: 45px;
}
#workshops-section .workshop-wrap .image-wrap {
  margin: 0 50px 0 0;
}
@media (max-width: 1200px) {
  #workshops-section .workshop-wrap .image-wrap {
    margin: 0;
  }
  #workshops-section .workshop-wrap .image-wrap img {
    display: none;
  }
}
#workshops-section .workshop-wrap.workshop-wrap-alt .image-wrap {
  margin: 0 0 0 50px;
}
@media (max-width: 1200px) {
  #workshops-section .workshop-wrap.workshop-wrap-alt .image-wrap {
    margin: 0;
  }
}
#workshops-section img {
  height: 100%;
  border-radius: 5px;
}
#workshops-section h2 {
  margin-top: 0;
}
@media (max-width: 1200px) {
  #workshops-section p.description {
    font-size: 20px;
  }
}
@media (max-width: 767px) {
  #workshops-section p.description {
    font-size: inherit;
  }
}
