.privacy-container {
  display: grid;
  margin: 120px 10% 0;
  grid-template-rows: auto auto auto;
  grid-template-columns: 1fr 120px 1fr;
}
@media (max-width: 1350px) {
  .privacy-container {
    grid-template-columns: 1fr 60px 1fr;
    margin: 120px 7% 0;
  }
}
@media (max-width: 992px) {
  .privacy-container {
    grid-template-columns: 1fr 40px 1fr;
    margin: 120px 5% 0;
  }
}
.privacy-container .privacy-heading {
  text-align: center;
  grid-column-start: 1;
  grid-column-end: 4;
  grid-row-start: 1;
  grid-row-end: 1;
  border-bottom: 1px solid #aaa;
  padding-bottom: 35px;
}
.privacy-container .privacy-heading img {
  width: 120px;
  height: 120px;
}
.privacy-container h3 {
  font-size: 24px;
  margin-bottom: 7px;
  margin-top: 35px;
}
@media (max-width: 992px) {
  .privacy-container h3 {
    font-size: 21px;
  }
}
@media (max-width: 820px) {
  .privacy-container h3 {
    font-size: 24px;
  }
}
.privacy-container p {
  font-size: 20px;
}
@media (max-width: 992px) {
  .privacy-container p {
    font-size: 18px;
  }
}
@media (max-width: 820px) {
  .privacy-container p {
    font-size: 21px;
  }
}
.privacy-container section.privacy-column {
  margin: 25px 0 45px;
}
.privacy-container section.privacy-column.privacy-eng {
  grid-column-start: 1;
  grid-column-end: 2;
  grid-row-start: 2;
  grid-row-end: 2;
}
@media (max-width: 820px) {
  .privacy-container section.privacy-column.privacy-eng {
    grid-column-start: 1;
    grid-column-end: 4;
  }
}
.privacy-container section.privacy-column.privacy-pl {
  grid-column-start: 3;
  grid-column-end: 4;
  grid-row-start: 2;
  grid-row-end: 2;
}
@media (max-width: 820px) {
  .privacy-container section.privacy-column.privacy-pl {
    grid-column-start: 1;
    grid-column-end: 4;
    grid-row-start: 3;
    grid-row-end: 3;
  }
}
.privacy-container section.privacy-column img.privacy-language {
  width: 55px;
  margin-top: 5px;
}
.privacy-container section.privacy-column ul {
  padding-left: 23px;
  font-size: 18px;
}
