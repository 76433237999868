#jobs-section {
  margin-bottom: 40px;
}
#jobs-section #jobsAccordion.panel-group {
  margin-top: 32px;
  margin-bottom: 0;
  border-radius: 5px;
  overflow: hidden;
}
#jobs-section #jobsAccordion.panel-group .panel-body {
  border: none;
  padding: 35px 50px;
  background: rgba(255,255,255,0.45);
}
#jobs-section #jobsAccordion.panel-group .panel {
  margin: 0;
  border-bottom: 1px solid rgba(165,97,181,0.27);
  border-top: none;
  border-left: none;
  border-right: none;
  background: rgba(165,97,181,0.16);
  -webkit-box-shadow: none;
  box-shadow: none;
}
#jobs-section #jobsAccordion.panel-group .panel:last-child {
  border-bottom: none;
}
#jobs-section #jobsAccordion.panel-group .panel a.job-panel-button {
  background-color: rgba(165,97,181,0.27);
  width: 100%;
  display: block;
  padding: 0 50px;
}
#jobs-section #jobsAccordion.panel-group .panel a.job-panel-button.collapsed {
  background-color: transparent;
}
#jobs-section #jobsAccordion.panel-group .panel a.job-panel-button.collapsed p {
  color: #864696;
}
#jobs-section #jobsAccordion.panel-group .panel a.job-panel-button.collapsed:hover {
  background-color: rgba(165,97,181,0.21);
  color: #864696;
}
#jobs-section #jobsAccordion.panel-group .panel a.job-panel-button.collapsed .job-arrow {
  -webkit-transform: rotate3d(1, 0, 0, 0deg);
  -moz-transform: rotate3d(1, 0, 0, 0deg);
  -o-transform: rotate3d(1, 0, 0, 0deg);
  -ms-transform: rotate3d(1, 0, 0, 0deg);
  transform: rotate3d(1, 0, 0, 0deg);
}
#jobs-section #jobsAccordion.panel-group .panel a.job-panel-button.collapsed .job-arrow polygon {
  fill: #6d3979;
}
#jobs-section #jobsAccordion.panel-group .panel a.job-panel-button p {
  margin: 0;
  text-transform: uppercase;
  font-family: "Nunito Sans";
  color: #6d3979;
  font-weight: 700;
  padding: 30px 0;
  font-size: 21px;
  letter-spacing: 0.5px;
  -webkit-transition: 0.35s all;
  -moz-transition: 0.35s all;
  -o-transition: 0.35s all;
  -ms-transition: 0.35s all;
  transition: 0.35s all;
}
#jobs-section #jobsAccordion.panel-group .panel a.job-panel-button .job-arrow {
  width: 24px;
  fill: #864696;
  float: right;
  margin-top: 35px;
  -webkit-transform: rotate3d(1, 0, 0, 180deg);
  -moz-transform: rotate3d(1, 0, 0, 180deg);
  -o-transform: rotate3d(1, 0, 0, 180deg);
  -ms-transform: rotate3d(1, 0, 0, 180deg);
  transform: rotate3d(1, 0, 0, 180deg);
  -webkit-transition: 0.35s all;
  -moz-transition: 0.35s all;
  -o-transition: 0.35s all;
  -ms-transition: 0.35s all;
  transition: 0.35s all;
  -webkit-transform-origin: center center;
  -moz-transform-origin: center center;
  -o-transform-origin: center center;
  -ms-transform-origin: center center;
  transform-origin: center center;
}
#jobs-section #jobsAccordion.panel-group .panel .panel-heading {
  padding: 0;
  border: none;
  background-color: transparent;
  -webkit-transition: 0.35s all;
  -moz-transition: 0.35s all;
  -o-transition: 0.35s all;
  -ms-transition: 0.35s all;
  transition: 0.35s all;
}
#jobs-section #jobsAccordion.panel-group .job-content {
  padding: 0 35px;
  border-left: 2px solid rgba(165,97,181,0.16);
}
@media (max-width: 767px) {
  #jobs-section #jobsAccordion.panel-group .job-content {
    border: none;
    padding: 0;
  }
}
#jobs-section #jobsAccordion.panel-group p {
  color: #3f3f3f;
  font-size: 18px;
}
#jobs-section #jobsAccordion.panel-group p.jobheading {
  font-family: 'Noto Sans';
  margin-bottom: 12px;
  color: #3c2a41;
  font-size: 24px;
}
#jobs-section #jobsAccordion.panel-group ul {
  padding-left: 25px;
  font-size: 20px;
  color: #a561b5;
}
#jobs-section #jobsAccordion.panel-group ul li p {
  margin: 5px 0 3px -2px;
}
#jobs-section #jobsAccordion.panel-group .job-button {
  text-align: left;
}
