.contact-container {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
}
.h-card.vcard {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-orient: vertical;
  -moz-box-orient: vertical;
  -o-box-orient: vertical;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
}
#contact-section {
  margin-top: 10px;
  margin-bottom: 0;
  padding: 35px 0 35px 55px;
}
#contact-section .contact-divider {
  width: 80px;
  height: 2px;
  background-color: rgba(165,97,181,0.16);
  margin-top: 25px;
  margin-bottom: 27px;
}
#contact-section h4 {
  line-height: 1.4;
}
#contact-section ul {
  list-style: none;
  padding: 0;
}
#contact-section ul li.contact-text {
  font-size: 18px;
}
#contact-section .p-name {
  font-weight: 700;
  margin-top: 15px;
}
#contact-section .work-cta {
  margin-top: 25px;
}
#contact-section .work-cta a.primary {
  margin: 10px 0;
}
#contact-section ul.social {
  list-style: none;
  margin: 20px 0;
  padding: 0;
  display: block;
  vertical-align: bottom;
}
@media (max-width: 1199px) {
  #contact-section ul.social {
    display: block;
    margin-left: 0;
    margin-top: 10px;
  }
}
@media (max-width: 992px) {
  #contact-section ul.social {
    display: block;
  }
}
#contact-section ul.social li {
  display: inline-block;
  margin: 0 20px 0 0;
}
#contact-section ul.social li:last-child {
  margin: 0;
}
#contact-section ul.social li a {
  display: block;
  height: 32px;
  border-radius: 3px;
  overflow: hidden;
}
#contact-section ul.social li a svg {
  height: 32px;
  width: 32px;
  opacity: 1;
  -ms-filter: none;
  filter: none;
  -webkit-transition: 0.25s all;
  -moz-transition: 0.25s all;
  -o-transition: 0.25s all;
  -ms-transition: 0.25s all;
  transition: 0.25s all;
  fill: #864696;
}
@media (max-width: 767px) {
  #contact-section ul.social li a svg {
    height: 40px;
    width: 40px;
  }
}
@media (max-width: 767px) {
  #contact-section ul.social li a {
    height: 40px;
  }
}
#contact-section ul.social li a:hover svg {
  fill: #6d3979;
}
.map-address a {
  color: #4f4f4f;
  display: inline-block;
}
.map-wrapper {
  width: 50%;
  height: 750px;
}
.map-wrapper #map {
  width: 100%;
  height: 100%;
}
@media (max-width: 1199px) {
  .map-wrapper #map {
    height: 585px;
  }
}
@media (max-width: 767px) {
  .map-wrapper #map {
    margin-top: 25px;
  }
}
@media (max-width: 992px) {
  .map-wrapper {
    display: none;
  }
}
