.home-lg {
  display: block;
  background-image: url(/lib/1ghCmJeU.png);
  -webkit-background-size: contain;
  -moz-background-size: contain;
  background-size: contain;
  background-repeat: no-repeat;
  width: 100%;
  height: 0;
  padding-top: 55.2083%;
  margin-bottom: -13%;
}
@media (max-width: 1350px) {
  .home-lg {
    display: none;
  }
}
.home-md {
  display: none;
  background-image: url(/lib/kNoV1hUC.png);
  -webkit-background-size: contain;
  -moz-background-size: contain;
  background-size: contain;
  background-repeat: no-repeat;
  width: 100%;
  height: 0;
  padding-top: 55.2083%;
  margin-bottom: -10%;
}
@media (max-width: 1350px) {
  .home-md {
    display: block;
  }
}
@media (max-width: 768px) {
  .home-md {
    display: none;
  }
}
.home-sm {
  display: none;
  background-image: url(/lib/3ademgv5.png);
  -webkit-background-size: contain;
  -moz-background-size: contain;
  background-size: contain;
  background-repeat: no-repeat;
  width: 100%;
  height: 0;
  padding-top: 126%;
  margin-bottom: -10%;
}
@media (max-width: 768px) {
  .home-sm {
    display: block;
  }
}
.home-image {
  margin-top: 60px;
/*
  img
    width 100%
    height auto
    margin-bottom -13%
    position relative
    @media (max-width: 1350px)
      margin-bottom -10%
      */
}
@media (max-width: 1200px) {
  .home-image {
    margin-top: 76px;
  }
}
.home-image p.slogan {
  position: absolute;
  color: #3c2a41;
  font-family: 'Noto Sans';
  top: 110px;
  right: 45px;
  font-size: 28px;
  text-align: right;
}
@media (max-width: 1200px) {
  .home-image p.slogan {
    font-size: 23px;
    right: 25px;
  }
}
@media (max-width: 992px) {
  .home-image p.slogan {
    font-size: 20px;
  }
}
@media (max-width: 768px) {
  .home-image p.slogan {
    font-size: 21px;
  }
}
.hero-link {
  font-size: 25px;
  border-bottom: 2px solid transparent;
}
.hero-link svg {
  height: 44px;
  width: 44px;
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
  vertical-align: middle;
  margin-bottom: 4px;
  fill: #a561b5;
  -webkit-transition: 0.3s all;
  -moz-transition: 0.3s all;
  -o-transition: 0.3s all;
  -ms-transition: 0.3s all;
  transition: 0.3s all;
}
.hero-link:hover svg {
  fill: #4d2855;
  -webkit-transform: rotate(180deg) translateX(-8px);
  -moz-transform: rotate(180deg) translateX(-8px);
  -o-transform: rotate(180deg) translateX(-8px);
  -ms-transform: rotate(180deg) translateX(-8px);
  transform: rotate(180deg) translateX(-8px);
}
.hero-link:hover {
  border-bottom: 2px solid #4d2855;
}
.top-message>div {
  -webkit-box-flex: 1;
  -moz-box-flex: 1;
  -o-box-flex: 1;
  box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}
.hero-section {
  height: calc(100vh - 60px);
  margin-top: 60px;
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-orient: vertical;
  -moz-box-orient: vertical;
  -o-box-orient: vertical;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: justify;
  -moz-box-pack: justify;
  -o-box-pack: justify;
  -ms-flex-pack: justify;
  -webkit-justify-content: space-between;
  justify-content: space-between;
  color: #000;
}
@media (max-width: 1200px) {
  .hero-section {
    height: calc(100vh - 76px);
    margin-top: 76px;
  }
}
@media (max-width: 475px) {
  .hero-section {
    height: 100%;
  }
}
@media (max-height: 580px) and (max-width: 1200px) {
  .hero-section {
    height: 100%;
  }
}
@media (max-height: 400px) {
  .hero-section {
    height: 100%;
  }
}
.hero-section img.logo-touk {
  filter: brightness(0);
  height: 100px;
  vertical-align: sub;
  margin-left: 10px;
  margin-top: -60px;
}
@media (max-width: 1600px) {
  .hero-section img.logo-touk {
    height: 80px;
  }
}
@media (max-width: 1350px) {
  .hero-section img.logo-touk {
    height: 70px;
  }
}
@media (max-width: 767px) {
  .hero-section img.logo-touk {
    height: 60px;
  }
}
@media (max-width: 475px) {
  .hero-section img.logo-touk {
    margin-top: 0;
  }
}
@media (max-height: 800px) {
  .hero-section img.logo-touk {
    height: 60px;
  }
}
.hero-section img.logo-snowflake {
  height: 80px;
  margin-left: 10px;
  margin-top: -5px;
}
@media (max-width: 1600px) {
  .hero-section img.logo-snowflake {
    height: 60px;
  }
}
@media (max-width: 1350px) {
  .hero-section img.logo-snowflake {
    height: 50px;
  }
}
@media (max-width: 767px) {
  .hero-section img.logo-snowflake {
    height: 40px;
  }
}
@media (max-height: 800px) {
  .hero-section img.logo-snowflake {
    height: 40px;
  }
}
.hero-section img.logo-nussknacker {
  height: 50px;
}
@media (max-width: 1600px) {
  .hero-section img.logo-nussknacker {
    height: 40px;
  }
}
@media (max-width: 1350px) {
  .hero-section img.logo-nussknacker {
    height: 35px;
  }
}
@media (max-width: 767px) {
  .hero-section img.logo-nussknacker {
    height: 30px;
  }
}
@media (max-height: 800px) {
  .hero-section img.logo-nussknacker {
    height: 30px;
  }
}
.hero-section strong {
  font-weight: 700;
}
.hero-section .top-message {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-orient: horizontal;
  -moz-box-orient: horizontal;
  -o-box-orient: horizontal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-align: stretch;
  -moz-box-align: stretch;
  -o-box-align: stretch;
  -ms-flex-align: stretch;
  -webkit-align-items: stretch;
  align-items: stretch;
  -webkit-box-flex: 1;
  -moz-box-flex: 1;
  -o-box-flex: 1;
  box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  background-image: url(/lib/2XiVQBKf.svg);
  background-repeat: no-repeat;
  background-position: bottom right;
  -webkit-background-size: contain;
  -moz-background-size: contain;
  background-size: contain;
}
@media (max-width: 1200px) {
  .hero-section .top-message {
    background-image: none;
    -webkit-box-orient: vertical;
    -moz-box-orient: vertical;
    -o-box-orient: vertical;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }
}
@media (max-height: 600px) {
  .hero-section .top-message {
    -webkit-background-size: 50%;
    -moz-background-size: 50%;
    background-size: 50%;
  }
}
@media (min-height: 900px) and (max-height: 1200px) {
  .hero-section .top-message {
    -webkit-background-size: 55%;
    -moz-background-size: 55%;
    background-size: 55%;
  }
}
.hero-section .top-message .top-message-left {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-orient: vertical;
  -moz-box-orient: vertical;
  -o-box-orient: vertical;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -moz-box-pack: center;
  -o-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -moz-box-align: center;
  -o-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  line-height: 1.75;
  font-size: 48px;
}
@media (max-width: 1600px) {
  .hero-section .top-message .top-message-left {
    font-size: 42px;
  }
}
@media (max-width: 1350px) {
  .hero-section .top-message .top-message-left {
    font-size: 36px;
  }
}
@media (max-width: 767px) {
  .hero-section .top-message .top-message-left {
    font-size: 29px;
  }
}
@media (max-height: 800px) {
  .hero-section .top-message .top-message-left {
    font-size: 29px;
  }
}
@media (max-width: 1200px) {
  .hero-section .top-message .top-message-left {
    -webkit-box-align: start;
    -moz-box-align: start;
    -o-box-align: start;
    -ms-flex-align: start;
    -webkit-align-items: flex-start;
    align-items: flex-start;
    padding: 0 30px;
  }
}
.hero-section .top-message .top-message-left a.hero-link {
  text-align: left;
}
.hero-section .top-message .top-message-left .hero-link {
  color: #29b5e8;
}
.hero-section .top-message .top-message-left .hero-link svg {
  fill: #29b5e8;
}
.hero-section .top-message .top-message-left .hero-link:hover svg {
  fill: #11567f;
}
.hero-section .top-message .top-message-left .hero-link:hover {
  color: #11567f;
  border-bottom: 2px solid #11567f;
}
@media (max-height: 800px) {
  .hero-section .top-message .top-message-left {
    line-height: 1.5;
  }
}
.hero-section .top-message .top-message-right {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-orient: vertical;
  -moz-box-orient: vertical;
  -o-box-orient: vertical;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: end;
  -moz-box-align: end;
  -o-box-align: end;
  -ms-flex-align: end;
  -webkit-align-items: flex-end;
  align-items: flex-end;
  -webkit-box-pack: end;
  -moz-box-pack: end;
  -o-box-pack: end;
  -ms-flex-pack: end;
  -webkit-justify-content: flex-end;
  justify-content: flex-end;
  margin-right: 4%;
  margin-bottom: 4%;
  line-height: 1.5;
  font-size: 42px;
}
@media (max-width: 1600px) {
  .hero-section .top-message .top-message-right {
    font-size: 36px;
  }
}
@media (max-width: 1350px) {
  .hero-section .top-message .top-message-right {
    font-size: 30px;
  }
}
@media (max-width: 767px) {
  .hero-section .top-message .top-message-right {
    font-size: 25px;
  }
}
@media (max-height: 800px) {
  .hero-section .top-message .top-message-right {
    font-size: 25px;
  }
}
@media (max-height: 800px) {
  .hero-section .top-message .top-message-right {
    margin-bottom: 3%;
    margin-right: 3%;
  }
}
@media (max-width: 1200px) {
  .hero-section .top-message .top-message-right {
    background-color: #c266ff;
    margin: 0;
    padding: 0 30px;
    -webkit-box-align: start;
    -moz-box-align: start;
    -o-box-align: start;
    -ms-flex-align: start;
    -webkit-align-items: flex-start;
    align-items: flex-start;
    -webkit-box-pack: center;
    -moz-box-pack: center;
    -o-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    margin-bottom: 0;
    margin-right: 0;
  }
}
.hero-section .top-message .top-message-right p {
  text-align: right;
  color: #fff;
}
@media (max-width: 1200px) {
  .hero-section .top-message .top-message-right p {
    text-align: left;
  }
}
.hero-section .top-message .top-message-right .hero-link {
  color: #fff;
}
.hero-section .top-message .top-message-right .hero-link svg {
  fill: #fff;
}
.hero-section .top-message .top-message-right .hero-link:hover {
  border-bottom: 2px solid #fff;
}
.hero-section .bottom-message {
  background: #b9c8d2;
  text-align: center;
  padding: 30px 0;
  font-size: 42px;
}
@media (max-width: 1600px) {
  .hero-section .bottom-message {
    font-size: 36px;
  }
}
@media (max-width: 1350px) {
  .hero-section .bottom-message {
    font-size: 30px;
  }
}
@media (max-width: 767px) {
  .hero-section .bottom-message {
    font-size: 25px;
  }
}
.hero-section p {
  margin: 0;
}
