.cookie-wrap {
  background-color: #fff;
  border-top: 2px solid rgba(165,97,181,0.35);
  border-right: 2px solid rgba(165,97,181,0.35);
  border-left: 2px solid rgba(165,97,181,0.35);
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
  position: fixed;
  bottom: 0;
  left: 50%;
  -webkit-transform: translate(-50%, 0);
  -moz-transform: translate(-50%, 0);
  -o-transform: translate(-50%, 0);
  -ms-transform: translate(-50%, 0);
  transform: translate(-50%, 0);
  padding: 15px 30px;
  -webkit-transition: 0.45s ease all;
  -moz-transition: 0.45s ease all;
  -o-transition: 0.45s ease all;
  -ms-transition: 0.45s ease all;
  transition: 0.45s ease all;
  overflow: hidden;
  z-index: 9000;
  display: none;
}
.cookie-wrap.cookie-hide {
  -webkit-transform: translate(-50%, 105%);
  -moz-transform: translate(-50%, 105%);
  -o-transform: translate(-50%, 105%);
  -ms-transform: translate(-50%, 105%);
  transform: translate(-50%, 105%);
}
.cookie-wrap p {
  font-size: 16px;
  margin: 0;
  line-height: 1.45;
}
.cookie-wrap a.primary {
  margin: 0;
  padding: 10px 30px;
}
.cookie-checkboxes {
  margin-bottom: 15px;
}
.cookie-checkbox {
  display: block;
  position: relative;
  padding-left: 26px;
  margin: 5px 20px 5px 0;
  cursor: pointer;
  font-size: 16px;
  font-weight: 400;
  display: inline-block;
}
.cookie-checkbox input {
  position: absolute;
  z-index: -1;
  opacity: 0;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  filter: alpha(opacity=0);
}
.cookie-checkbox-indicator {
  position: absolute;
  top: 2px;
  left: 0;
  height: 20px;
  width: 20px;
  background: #e6e6e6;
  border-radius: 3px;
}
.cookie-checkbox:hover input ~ .cookie-checkbox-indicator,
.cookie-checkbox input:focus ~ .cookie-checkbox-indicator {
  background: #ccc;
}
.cookie-checkbox input:checked ~ .cookie-checkbox-indicator,
.cookie-checkbox input:checked:focus ~ .cookie-checkbox-indicator {
  background: #a561b5;
}
.cookie-checkbox:hover input:not([disabled]):checked ~ .cookie-checkbox-indicator {
  background: #6d3979;
}
.cookie-checkbox input:disabled ~ .cookie-checkbox-indicator {
  background: #e6e6e6;
  opacity: 0.6;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=60)";
  filter: alpha(opacity=60);
  pointer-events: none;
}
.cookie-checkbox-indicator:after {
  content: '';
  position: absolute;
  display: none;
}
.cookie-checkbox input:checked ~ .cookie-checkbox-indicator:after {
  display: block;
}
.cookie-checkbox .cookie-checkbox-indicator:after {
  left: 8px;
  top: 3px;
  width: 5px;
  height: 12px;
  border: solid #fff;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
