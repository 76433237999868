html.oldbrowser-html {
  height: 100%;
  display: table;
  text-align: center;
  width: 100%;
}
body.oldbrowser-body {
  background-color: #eee;
  margin: 0;
  padding: 0;
  background-image: url(/lib/XBYEGaox.png);
  -webkit-background-size: auto;
  -moz-background-size: auto;
  background-size: auto;
  background-repeat: no-repeat;
  font-family: "Work Sans";
  color: #a3a3a3;
  vertical-align: middle;
  width: 100%;
  height: 100%;
  min-height: 100%;
  display: table-cell;
}
body.oldbrowser-body p.oldbrowser-title {
  font-size: 60px;
  margin: 0 0 40px 0;
  font-family: "Work Sans";
  text-transform: uppercase;
}
body.oldbrowser-body p {
  font-size: 30px;
}
